@import './variables.scss';

* {
  box-sizing: border-box;
}

html {
  font-family: $bodyfont;
  font-size: 16px;
  font-weight: 300;
  background-color: $light;
}

h2 {
  font-family: $headersfont;
  font-weight: 300;
  font-size: 20px;
  text-transform: uppercase;
}

a {
  color: $dark;
}

a:visited {
  color: $dark;
}

a:hover {
  color: $highlight;
}
