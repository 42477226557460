@import '../../base-styles/variables.scss';

#contact {
  text-align: center;
  background-color: $medium;
  height: 50%;
  border-bottom: 2px solid $highlight;

  img {
    width: 50px;
  }

  p {
    margin: 0px 10px;
  }

  img:hover {
    background-color: $highlight;
    border-radius: 25%;
  }

  h2 {
    padding-top: 40px;
    margin-bottom: 20px;
  }

  ul {
    width: 50%;
    margin: 20px 25%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }
}
