// Colors

$light: #d4d4dc;
$medium: #d4d4dc;
$dark: rgb(12, 12, 12);
$highlight: #393f4d;

// Fonts
$headersfont: 'Lato', sans-serif;
$bodyfont: 'Crimson Text', sans-serif;
