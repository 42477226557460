@import '../../base-styles/variables.scss';

#work {
  border-bottom: 2px solid $highlight;
  padding-bottom: 40px;

  h2 {
    text-align: center;
    padding-top: 70px;
  }

  h3 {
    font-family: $headersfont;
    font-size: 30px;
    text-transform: lowercase;
    text-align: center;
    margin: 20px 0px;
  }

  #tech-content {
    width: 95%;
    margin: 0px auto;
    display: grid;
    grid-template-columns: 50% 50%;

    div {
      margin: 20px auto;
      width: 450px;

      h4 {
        font-family: $headersfont;
        font-size: 30px;
        margin-bottom: 10px;
      }

      img {
        width: 450px;
        box-shadow: 3px 3px 5px 6px #ccc;
      }

      p {
        margin: 10px 0px;
      }

      span {
        font-weight: 500;
      }

      .tech {
        height: 40px;
      }

      a {
        display: block;
        text-decoration: none;
        font-weight: 400;
        color: $highlight - 30;
      }

      a:hover {
        font-style: italic;
        color: $highlight;
      }
    }
  }

  #marketing-content {
    width: 95%;
    margin: 0px auto;
    display: grid;
    grid-template-columns: 50% 50%;

    div {
      margin: 20px auto;
      width: 450px;

      h4 {
        font-family: $headersfont;
        font-size: 30px;
        margin-bottom: 10px;
      }

      img {
        width: 450px;
        // box-shadow: 0.1;
      }

      p {
        margin: 10px 0px;
      }

      #market1 {
        height: 125px;
      }

      #market2 {
        height: 160px;
      }

      span {
        font-weight: 500;
      }

      a {
        display: block;
        text-decoration: none;
        font-weight: 400;
        color: $highlight - 30;
      }

      a:hover {
        font-style: italic;
        color: $highlight;
      }
    }
  }
}

@media screen and (max-width: 975px) and (min-width: 800px) {
  #work {
    h2 {
      padding-top: 120px;
    }

    #tech-content {
      div {
        width: 350px;

        img {
          width: 350px;
        }

        p {
          width: 350px;
        }

        .tech {
          height: 60px;
        }
      }
    }

    #marketing-content {
      div {
        width: 350px;

        img {
          width: 350px;
        }

        p {
          width: 350px;
        }

        #market1 {
          height: 160px;
        }

        #market2 {
          height: 200px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  #work {
    h2 {
      padding-top: 120px;
    }

    #tech-content {
      display: flex;
      flex-direction: column;
      width: 85%;
      div {
        width: 100%;

        img {
          width: 100%;
        }

        p {
          width: 100%;
        }

        .tech {
          height: 100%;
        }
      }
    }

    #marketing-content {
      display: flex;
      flex-direction: column;
      width: 85%;

      div {
        width: 100%;

        img {
          width: 100%;
        }

        p {
          width: 100%;
        }

        #market1 {
          height: 100%;
        }

        #market2 {
          height: 100%;
        }
      }
    }
  }
}
